jQuery(document).ready(function($){
	var Start = $('#StartDate');
	var End = $('#EndDate');
	var start = /start:(\d||-)+/;
	var end = /end:(\d||-)+/;

	if (Start)
	{
		var start_url = String(window.location);
		var start_value = start_url.includes('start') ? (start_url.match(start)[0]).substring(6) : '';
		Start.val(start_value);
	}

	if (End)
	{
		var end_url = String(window.location);
		var end_value = end_url.includes('end') ? (end_url.match(end)[0]).substring(4) : '';
		End.val(end_value);
	}

	Start.on('change', function(){
		var url = String(window.location);
		url = url.includes('start') ? url.replace(start, 'start:' + Start.val()) : url + '/start:' + Start.val();
		window.location = url;
	});

	End.on('change', function(){
		var url = String(window.location);
		url = url.includes('end') ? url.replace(end, 'end:' + End.val()) : url + '/end:' + End.val();
		window.location = url;
	});
});

jQuery(document).ready(function($) {
	$('.modal.add-payment').each(function(modal){
		var AddPayment = $(this);

		var PaymentSubmits     = AddPayment.find('button[type=submit]');
		var AddPaymentForm     = AddPayment.find('form');
		var EditPaymentTotal   = AddPayment.find('.edit-payment-total');
		var FormPaymentTotal   = AddPayment.find('.form-payment-total');
		var FormPaymentDefault = AddPayment.find('.form-payment-default');
		var PaymentTotal       = AddPayment.find('.payment-total');
		var PaymentLocalTax    = AddPayment.find('.payment-local-tax');
		var ReferralText       = AddPayment.find('.referral-text');
		var ImplementationText = AddPayment.find('.implementation-text');

		var TotalCharge        = AddPaymentForm.find('.total_charge');

		var PAYMENT_MONTHS     = parseInt(AddPayment.find('.payment-months').val());

		EditPaymentTotal.click(function(){
			FormPaymentTotal.toggleClass('hide');
			FormPaymentDefault.toggleClass('hide');

			PaymentTotal.attr('disabled', FormPaymentTotal.hasClass('hide'));
			PaymentLocalTax.attr('disabled', FormPaymentTotal.hasClass('hide'));
		});
		AddPaymentForm.on('submit', function(event){
			if (PaymentSubmits.attr('disabled') === 'disabled')
			{
				return event.preventDefault();
			}
			PaymentSubmits.attr('disabled', 'disabled');
		});

		AddPayment.find('.conekta-charge').change(function(){
			var will_charge = $(this).is(':checked');

			AddPayment.find('.payment-paid').toggleClass('hide', will_charge);
			AddPayment.find('.conekta-credit-card').toggleClass('hide', !will_charge);
			AddPayment.find('.modal-footer .btn-info').toggleClass('hide', will_charge);
			AddPayment.find('.modal-footer .btn-success').toggleClass('hide', !will_charge);
		});

		function calculate_total()
		{
			var total = parseFloat(PaymentTotal.val());
			var local_tax = parseFloat(PaymentLocalTax.val());

			local_tax = isNaN(local_tax) ? 0 : local_tax / 100;

			TotalCharge.html('$' + (total * (1 + local_tax)).formatMoney());
		}

		ReferralText.html(PAYMENT_MONTHS === 1 ? '100%' : '1 mes');
		ImplementationText.html(PAYMENT_MONTHS === 1 ? '100%' : '1 mes');

		PaymentTotal.keyup(calculate_total).change(calculate_total);
		PaymentLocalTax.keyup(calculate_total).change(calculate_total);

		calculate_total();
	});
});

/**
 * Price beautifier bullshit.
 *
 * @param  int    price
 * @param  string currency
 * @return float
 */
 function price_beautifier(price, currency)
 {
	 var rounder    = 1;
	 var obfuscator = 0.0;
	 var precision  = 0;
 
	 if (currency === 'CLP' || currency === 'COP')
	 {
		 rounder = 1000;
	 }
	 else if (currency === 'USD' || currency === 'CAD' || currency === 'EUR' || currency === 'PEN')
	 {
		 obfuscator = 0.01
	 }
 
	 var new_price = Math.round(price / rounder, precision) * rounder - obfuscator;
 
	 return new_price > 0 ? new_price : price;
 }
 
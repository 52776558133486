jQuery(document).ready(function($) {

	$('form.form-commissions').each(function(){
		var form = $(this);
		var ticket_total = form.find('.ticket-list-total');
		var checkboxes = form.find('input[type=checkbox]');

		checkboxes.change(function(event){
			var total = 0;
			for (var n = 0; n < checkboxes.length; n++)
			{
				var checkbox = $(checkboxes[n]);
				if (checkbox.prop('checked'))
				{
					total += parseFloat(checkbox.attr('data-commission'));
				}
			}
			ticket_total.html('$' + total.formatMoney());
		});
	});

});

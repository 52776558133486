jQuery(document).ready(function($) {

	$('.enable-popover').popover();
	$('.enable-tooltip').tooltip();

	var GlobalSearchInput = $('#GlobalSearchInput');
	if (GlobalSearchInput)
	{
		var html = '';
		var GlobalSubmitSearch = $('#GlobalSubmitSearch');
		var GlobalSearchResults = $('#GlobalSearchResults');
		var company_statuses = {
			'-1': '<span class="label label-default">Sin activar</span>',
			'0':  '<span class="label label-default">Desactivada</span>',
			'1':  '<span class="label label-success">Normal</span>',
			'2':  '<span class="label label-warning">Stand by</span>',
			'3':  '<span class="label label-danger">Usuarios bloqueados</span>',
		};

		var search_ajax = function(event)
		{
			if (event.keyCode === 13 || event.keyCode === undefined)
			{
				var keywords = GlobalSearchInput.prop('value');
				var request = $.ajax({
					url: '/search',
					type: 'POST',
					data: {keywords: keywords},
					dataType: 'json'
				});
				request.done(function(response){
					var results = response.results;
					var list = [];

					for (var i = 0, limit = results.length; i < limit; i++)
					{
						var company_status_id = results[i].Company.active ? results[i].Company.company_status_id : '0';
						if (!results[i].Company.active && results[i].UserDevice.id === null)
						{
							company_status_id = '-1';
						}
						list.push([
							'<li><a href="/companies/view/' + results[i].Company.id + '">',
								'<small class="text-right pull-right text-muted" style="line-height: 1.55em">',
									results[i].Plan.name,
									'<br />',
									company_statuses[company_status_id],
								'</small>',
								'<span style="margin-right: 120px">',
									results[i].Company.corporate_name,
								'</span>',
								'<br />',
								'<small class="text-muted" style="margin-right: 120px">',
									results[i].User.username,
								'</small>',
							'</a></li>'
						].join(''));
					}
					GlobalSearchResults.html(list.join(''));
					GlobalSearchResults.attr('data-result', list.length > 0 ? 'true' : 'false');
				});
			}
		}

		GlobalSearchInput.keydown(search_ajax);
		GlobalSubmitSearch.click(search_ajax);
	}

	$('.view-email-content').click(function(event){
		event.preventDefault();
		$.ajax({
			url: $(this).attr('href')
		}).done(function(result){
			$('#EmailContent').html(result);
			$('#ModalEmailContents').modal('show');
		});
	});

	function months_difference(d1, d2)
	{
		var m1 = (d1.getFullYear() * 12) + d1.getMonth() + 1;
		var m2 = (d2.getFullYear() * 12) + d2.getMonth() + 1;

		return m2 - m1;
	}

	var NavTabsCompanyView = $('#NavTabsCompanyView');
	if (NavTabsCompanyView.length > 0)
	{
		var url = document.URL.split('#');
		if (url[1])
		{
			jQuery('#NavTabsCompanyView a[href="#' + url[1] + '"]').tab('show');
		}
	}

	$('.partener-notfification').click(function(){
		$($(this).attr('data-target')).modal('show');
	});
});

jQuery(window).ready(function($){
	if (typeof LOOKUPS != 'object')
	{
		return;
	}

	var Table = $('#ScaffoldTableIndex');

	function populateTable(id, lookup)
	{
		var Row = Table.find('[lookup="' + id + '"]');

		Row.find('[data-lookup="table"]').text(lookup.table);
		Row.find('[data-lookup="query"]').text(lookup.query);
		Row.find('[data-lookup="without"]').text(lookup.without);
		Row.find('[data-lookup="badge_status"]').addClass(lookup.badge_status).text(lookup.total);
		Row.find('.delete-garbage').click(function(event){
			event.preventDefault();

			$.ajax({
				url: $(this).attr('href'),
				type: 'GET',
				success: function(){
					Row.find('[data-lookup="badge_status"]').attr('class', 'badge badge-success').text(0);
				},
				error: function(){
					window.location = event.target;
				}
			});
		});
	}

	var requests = 0;

	(function fetchLookups(){
		$.ajax({
			url: '/garbage/lookup.json',
			type: 'POST',
			data: LOOKUPS[requests],
			success: function(res){
				populateTable(requests, res.lookup);
			},
			complete: function(){
				if (LOOKUPS.length - 1 > requests++)
				{
					fetchLookups();
				}
			}
		});
	})();
});

jQuery(window).ready(function($){
	function activation(x)
	{
		return (5 / (1 + Math.pow(Math.E, -(x / 3 - 1))) - 1) * 1.1;
	}

	var CATEGORIES = [
		{
			key: 'portfolio',
			name: 'Clientes y prospectos modificados',
			colors: ['#d9534f', '#e4804e', '#f0ad4e', '#a6b255', '#5cb85c']
		},
		{
			key: 'actions',
			name: 'Acciones creadas y respondidas',
			colors: ['#d9534f', '#e4804e', '#f0ad4e', '#a6b255', '#5cb85c']
		},
		{
			key: 'price_quotes',
			name: 'Cotizaciones enviadas',
			colors: ['#777777', '#e4804e', '#f0ad4e', '#a6b255', '#5cb85c']
		},
		{
			key: 'email_manual',
			name: 'Correos enviados manualmente',
			colors: ['#999999', '#e4804e', '#f0ad4e', '#a6b255', '#5cb85c']
		},
		{
			key: 'email_marketing',
			name: 'Campañas de Email Marketing',
			colors: ['#bbbbbb', '#e4804e', '#f0ad4e', '#a6b255', '#5cb85c']
		},
	]
	var WEEKDAYS = [
		'Domingo',
		'Lunes',
		'Martes',
		'Miércoles',
		'Jueves',
		'Viernes',
		'Sábado',
	]

	function fingerprint(raw_element, options)
	{
		var element = jQuery(raw_element)
		if (!SVG.supported)
		{
			return alert('SVG not supported')
		}

		var usage = options.usage

		var width = element.width()
		var height = element.height()

		var draw = SVG(raw_element).size(width, height)

		var square_space = parseInt(height / CATEGORIES.length)
		var square_size = height > 30 ? parseInt(square_space * 0.8) : square_space

		var square_width = height > 30 ? square_size : parseInt(width / usage[CATEGORIES[0].key].length);
		var square_height = height > 30 ? square_size : parseInt(height / CATEGORIES.length)

		var line_height = height / 3
		var text_top_padding = line_height * 0.2
		var text_size = 12
		var text_start = usage[CATEGORIES[0].key].length * square_space + square_space / 2

		for (var i = 0; i < CATEGORIES.length; i++)
		{
			var key = CATEGORIES[i].key

			for (var j = 0; j < usage[key].length; j++)
			{
				var padding = (square_space - square_size) / 2
				var color_selected = parseInt(activation(usage[key][j]))

				var rect = draw
					.rect(square_width, square_height)
					.radius(height > 30 ? 2 : 0)
					.move(j * square_space + padding, i * square_space + padding)
					.fill(CATEGORIES[i].colors[color_selected])

				if (options.add_text)
				{
					var human_date = (j == usage[key].length - 1) ? 'Hoy' : 'Ayer';
					if (j < usage[key].length - 2)
					{
						var date = new Date();
						date.setDate(date.getDate() - (usage[key].length - 1 - j))
						human_date = WEEKDAYS[date.getDay()]
					}

					(function(title, total, day){
						var label_title = null
						var label_total = null
						var label_day = null
						var font_options = {fill: '#404040', size: text_size, family: 'Inconsolata'}

						rect.on('mouseover', function() {
							label_title = draw.text(title).move(text_start, text_top_padding).font(font_options)
							label_total = draw.text('Total: ' + total).move(text_start, line_height + text_top_padding).font(font_options)
							label_day   = draw.text('Día: ' + day).move(text_start, line_height * 2 + text_top_padding).font(font_options)
							this.scale(1.5, 1.5)
						})
						rect.on('mouseout', function() {
							if (label_title)
							{
								label_title.remove()
								label_total.remove()
								label_day.remove()
							}
							this.scale(1, 1)
						})
					})(CATEGORIES[i].name, usage[key][j], human_date);
				}
			}
		}

		return draw
	}

	function compact_fingerprint(raw_element, usage)
	{
		var element = jQuery(raw_element)
		if (!SVG.supported)
		{
			return alert('SVG not supported')
		}

		var width = element.width()
		var height = element.height()

		var draw = SVG(raw_element).size(width, height)

		var square_space = height / 1
		var square_size = parseInt(square_space * 0.8)

		var centering_padding = parseInt((width - (square_space * CATEGORIES.length)) / 2)
		var workable_natural_days = 5 / 7;

		for (var i = 0; i < CATEGORIES.length; i++)
		{
			var key = CATEGORIES[i].key;

			var total = usage[key] ? usage[key].reduce(function(a, b) {
				return a + b
			}) : 0
			total = parseInt(total / (usage[key].length * workable_natural_days))

			var padding = parseInt((square_space - square_size) / 2)
			var rect = draw
				.rect(square_size, square_size)
				.radius(2)
				.move(i * square_space + padding + centering_padding, padding)
				.fill(CATEGORIES[i].colors[parseInt(activation(total))])
		}

		return draw
	}

	jQuery('.fingerprint:not(.fingerprint-compact)').each(function(){
		fingerprint(this, {
			usage: jQuery(this).data('usage'),
			add_text: true,
		});
	});

	jQuery('.fingerprint-compact').each(function(){
		compact_fingerprint(this, jQuery(this).data('usage'));
	});
});

jQuery(document).ready(function($) {
	$('.modal.extra-payment').each(function(modal){
		var AddPayment = $(this);

		var PaymentSubmits = AddPayment.find('button[type=submit]');
		var UnitPrice      = AddPayment.find('.unit_price');
		var TotalCharge    = AddPayment.find('.total_charge');

		AddPayment.find('form').on('submit', function(event){
			if (PaymentSubmits.attr('disabled') === 'disabled')
			{
				return event.preventDefault();
			}
			PaymentSubmits.attr('disabled', 'disabled');
		});

		function calculate_total()
		{
			var total = parseFloat(UnitPrice.val());
			var local_tax = parseFloat(UnitPrice.data('local-tax'));

			local_tax = isNaN(local_tax) ? 0 : local_tax;

			TotalCharge.html('$' + (total * (1 + local_tax)).formatMoney());
		}

		UnitPrice.keyup(calculate_total).change(calculate_total);

		calculate_total();
	});
});

jQuery(document).ready(function($) {

	var modalResetPassword = $('#resetPassword');
	var hash = modalResetPassword.find('.hash');
	var generateHash = modalResetPassword.find('.generate-hash')
	var reset;

	modalResetPassword.on('show.bs.modal', function(e) {
		generateHash.attr('data-user-id', $(e.relatedTarget).attr('data-user-id'));
		generateHash.removeAttr('disabled');
		generateHash.removeClass('disabled');
	});

	modalResetPassword.on('hidden.bs.modal', function(e){
		generateHash.removeClass('hide');
		generateHash.attr('disabled', 'disabled');
		generateHash.addClass('disabled');
		hash.addClass('hide');
		hash.html('');
	});

	generateHash.click(function(){
		var url = [
			'/users/reset_password_url/',
			generateHash.attr('data-user-id'),
			'.json'
		];

		$.get(url.join(''), function(data) {
			if (data.error)
			{
				location.reload();
				return;
			}
			reset = [
				URL_DOMAIN,
				'/api/reset/',
				data.reset.PasswordReset.hash
			];
			hash.html(reset.join(''));
			hash.removeClass('hide');
			generateHash.addClass('hide');
		});
	});
});

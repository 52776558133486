jQuery(document).ready(function($){
	$('#Payments .payment-type-info').each(function(){
		var $payment = $(this);
		var item = '<p><strong>{key}</strong>: <span style="margin-left: 1em;" class="pull-right">{value}</span></p>';
		$payment.popover({
			title: 'Información de pago',
			html: true,
			content: function(){
				var order_id = $payment.data('order_id');

				var payment_method = $payment.data('payment_method');
				var fee = $payment.data('fee') ? '$' + $payment.data('fee') / 100 : '–';
				var method = payment_method ? payment_method.brand + ' / *' + payment_method.last4 : '–';
				var link = order_id ? 'https://admin.conekta.com/orders/' + order_id : false;
				var anchor = link ? '<a href="' + link + '" target="_blank">Abrir</a>' : '–';

				return [
					item.replace('{key}', 'Tipo de pago').replace('{value}', $payment.text()),
					item.replace('{key}', 'Comisión').replace('{value}', fee),
					item.replace('{key}', 'Método').replace('{value}', method),
					item.replace('{key}', 'Enlace').replace('{value}', anchor),
				].join('');
			}
		}).css('cursor', 'pointer');
	});

	var add_events = function()
	{
		var form = upgrade_modal.find('#CompanyUpgradeForm');
		var field_error = form.find('.field-error');
		var message_error = form.find('.text-danger');
		var CompanyPartnerId = $('#CompanyPartnerId');
		var CompanyPlanId = $('#CompanyPlanId');

		CompanyPlanId.change(function(){
			payments_plans();
		});

		CompanyPartnerId.change(function(event){
			validate_share(CompanyPartnerId.val());
		});

		form.submit(function(event){
			event.preventDefault();
			$.ajax({
				url: form.attr('action') + '.json',
				data: form.serialize(),
				type: 'POST',
			}).done(function(response) {
				field_error.removeClass('has-error');
				message_error.html('');
				if (response.success)
				{
					location.reload();
				}
				else
				{
					$.each(response.errors, function(element, error) {
						var row = form.find('.' + element);
						row.addClass('has-error');
						row.find('.text-danger').html(error[0])
					});
				}
			});
		});
		validate_share(CompanyPartnerId.val());
	}
	var payments_plans = function(){
		var CompanyPlanId = $('#CompanyPlanId');
		var CompanyPaymentPlanId = $('#CompanyPaymentPlanId');
		var value = CompanyPaymentPlanId.find('option:selected').text();
		$.ajax({
			url: '/companies/payment_plans/' + CompanyPlanId.val() + '.json',
			context: document.body
		}).done(function(response) {
			var options = '<option value="">Mensual</option>';
			var new_selection = ''
			for (var key in response.list_payment_plans)
			{
				if (!response.list_payment_plans.hasOwnProperty(key))
				{
					continue;
				}
				options += '<option value="' + key + '">' + response.list_payment_plans[key] + '</option>';
				if (value === response.list_payment_plans[key])
				{
					new_selection = key;
				}
			}
			CompanyPaymentPlanId.html(options);
			CompanyPaymentPlanId.val(new_selection);
		});
	}

	$('input[required=required], select[required=required]').attr('required', false);
	$('.form-horizontal .form-group.error').each(function() {
		var form_group = $(this);
		var alert_message = form_group.children('.alert');
		var form_control = form_group.children('.form-control');
		var message = alert_message.html();

		form_group.attr({
			'data-toggle': 'tooltip',
			'data-placement': 'top',
			'title': message
		}).tooltip();

		alert_message.remove();
	});
	var CompanyPartnerId = $('#CompanyPartnerId');
	var CompanyPlanId = $('#CompanyPlanId');

	CompanyPlanId.change(function(){
		payments_plans();
	});

	CompanyPartnerId.change(function(){
		validate_share($(this).val());
	});

	var validate_share = function validate_share(partner_id)
	{
		var CompanyPartnerShare = $('#CompanyPartnerShare');
		var CompanyPartnerShareOptions = CompanyPartnerShare.find('option');

		if (partner_id == 1)
		{
			CompanyPartnerShare.val('').attr('readonly', 'readonly');
			CompanyPartnerShareOptions.addClass('hide');
		}
		else
		{
			CompanyPartnerShare.removeAttr('readonly');
			CompanyPartnerShareOptions.removeClass('hide');
		}
	}
	validate_share(CompanyPartnerId.val());

	if ($('#CompanyUpgradeForm'))
	{
		var upgrade_modal = $('#Upgrade');
		upgrade_modal.on('loaded.bs.modal', function(){
			add_events();
		});
	}

	var NextPaymentTotal = $('#NextPaymentTotal');
	if (NextPaymentTotal.length && NEXT_PAYMENT)
	{
		var html = [];
		for (var i = 0; i < NEXT_PAYMENT.PaymentConcept.length; i++)
		{
			var concept = NEXT_PAYMENT.PaymentConcept[i];

			html.push(
				'<tr><td>' +
				concept.name +
				'</td><td>' +
				(concept.quantity > 1 ? '[' + concept.quantity + ']' : '') +
				'</td><td>$' +
				concept.unit_price.formatMoney() +
				'</td></tr>'
			);
		}
		var discount = parseFloat(NEXT_PAYMENT.Payment.discount);
		if (discount > 0)
		{
			html.push('<tr><td>Descuento:</td><td></td><td>' + discount + '%</td></tr>');
		}

		var plan_months = parseInt(NEXT_PAYMENT.Payment.plan_months);
		var text_months = plan_months === 1 ? 'Un mes' : plan_months + ' meses';
		html.push('<tr><td>Contratación:</td><td></td><td>' + text_months + '</td></tr>');

		var table_concepts = '<table class="table-minimal">' + html.join('') + '</table>';

		NextPaymentTotal.popover({
			content: table_concepts,
			html: true,
			placement: 'left'
		});

		$('.payment_details').html(table_concepts);
	}
});

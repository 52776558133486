jQuery(document).ready(function($){
	var ModalDiscount       = $('#ModalDiscount');
	var ModalDiscountBtn    = $('#ModalDiscountBtn');
	var ApplyDiscount       = $('#ApplyDiscount');
	var DiscountInput       = $('#DiscountInput');
	var PlanDiscount        = $('#PlanDiscount');
	var PlanId              = $('#CompanyPlanId');
	var PaymentPlanId       = $('#CompanyPaymentPlanId');
	var ExtraUsers          = $('#CompanyExtraUsers');
	var ExtraClients        = $('#CompanyExtraClients');
	var ExtraMails          = $('#CompanyExtraMails');
	var ExtraSpace          = $('#CompanyExtraSpace');
	var ExtraInvoices       = $('#CompanyExtraInvoices');
	var PlanDetails         = $('#PlanDetails');
	var SubtotalDetails     = $('#SubtotalDetails');
	var TotalDetails        = $('#TotalDetails');

	var plan_price = 0;
	var user_price = 0;
	var months = 0;
	var extras = 0;
	var tax = 0;

	ApplyDiscount.click(function(e){
		var discount = calculate_total(plan_price, user_price, extras, months, render_prices);
		if (discount < 0 || discount > 100)
		{
			alert('El descuento no puede ser mayor al 100% ni menor al 0%');
			return;
		}
		PlanDiscount.val(discount);
		ModalDiscount.modal('hide');
	});

	if (ModalDiscount)
	{
		ModalDiscount.on('hidden.bs.modal', function(){
			DiscountInput.val(0)
		});
	}

	if (ModalDiscountBtn)
	{
		ModalDiscountBtn.click(function(){
			get_data();
		});
	}

	if (DiscountInput)
	{
		DiscountInput.keyup(function(){
			calculate_total(plan_price, user_price, extras, months, render_prices);
		}).keypress(function(e){
			if (e.which == 13)
			{
				return false;
			}
		});
	}

	function get_data()
	{
		var url = window.location.pathname;
		url = url.split('/');
		$.ajax({
			url: '/plans/getPlanAndPaymentById.json',
			data: {'plan_id': PlanId.val(), 'company_id': url[3]}
		}).done(function(response){
			var payment_plan = get_payment_plan(response['plan']['PaymentPlan']);
			var plan = (payment_plan) ? payment_plan : response['plan']['Plan'] ;

			months = (payment_plan) ? parseInt(payment_plan.months) : 1 ;
			plan_price = parseInt(plan.price);
			user_price = Math.round(parseFloat(plan.user_price));
			tax = response.tax;
			extras = calculate_extras(response['extras'][0]['PlanExtra'], ExtraClients);
			extras += calculate_extras(response['extras'][1]['PlanExtra'], ExtraMails);
			extras += calculate_extras(response['extras'][2]['PlanExtra'], ExtraSpace);
			extras += calculate_extras(response['extras'][3]['PlanExtra'], ExtraInvoices);

			var current_discount = PlanDiscount.val() === '' ? 0.0 : parseFloat(PlanDiscount.val());

			var saved_discount = months * (plan_price + extras + (user_price * parseInt(ExtraUsers.val())));
			saved_discount = saved_discount * (100 - current_discount) / 100;
			saved_discount = !isFinite(saved_discount) ? 0 : Math.round(saved_discount * 100) / 100;
			DiscountInput.val(saved_discount);

			render_details(plan);
			calculate_total(plan_price, user_price, extras, months, render_prices);
		});
	}

	function get_payment_plan(payment_plans)
	{
		if (PaymentPlanId.val() !== '')
		{
			for (var key in payment_plans)
			{
				if (payment_plans[key].id == PaymentPlanId.val())
				{
					return payment_plans[key];
				}
			}
		}
	}

	function calculate_total(plan_price, user_price, extras, months, render_prices)
	{
		var subtotal = (ExtraUsers.val()) ? user_price * parseInt(ExtraUsers.val()) : 0 ;
		subtotal = months * (subtotal + extras + plan_price);

		var discount_desired = DiscountInput.val() === '' ? subtotal : parseFloat(DiscountInput.val());

		var discount_percentage = get_discount_percentage(discount_desired, subtotal);
		discount_percentage = isNaN(discount_percentage) ? 100 : discount_percentage;

		var discount = (discount_percentage * subtotal) / 100;
		var subtotal_with_discount = parseFloat(subtotal - discount);
		render_prices(discount_percentage, subtotal, subtotal_with_discount);
		return discount_percentage;
	}

	function get_discount_percentage(discount, subtotal)
	{
		var discount_percentage = 100 - ((discount * 100) / subtotal);
		return parseFloat(discount_percentage.toFixed(8));
	}

	function calculate_extras(extra, input)
	{
		var unit_price = parseFloat(extra.unit_price) / 100;
		var input_value = parseFloat(input.val());
		var extra_total = unit_price * input_value / parseFloat(extra.resource_amount);
		return extra_total;
	}

	function render_details(plan)
	{
		var plan_html = [];

		plan_html.push(
			'<div class="col-xs-8">',
				'<p>Plan ' + PlanId.children('option:selected').html() + ':</p>',
			'</div>',
			'<div class="col-xs-4">',
				'<p>$' + parseInt(plan.price).formatMoney() + '</p>',
			'</div>',
			'<div class="col-xs-8">',
				'<p>Contratacion:</p>',
			'</div>',
			'<div class="col-xs-4">',
				'<p>' + PaymentPlanId.children('option:selected').html() + '</p>',
			'</div>'
		);

		if (parseInt(ExtraUsers.val()) > 0)
		{
			plan_html.push(
				'<div class="col-xs-8">',
					'<p>Usuarios extra:</p>',
				'</div>',
				'<div class="col-xs-4">',
					'<p>' + ExtraUsers.val() + '</p>',
				'</div>',
				'<div class="col-xs-8">',
					'<p>Precio usuario:</p>',
				'</div>',
				'<div class="col-xs-4">',
					'<p>$' + Math.round(parseFloat(plan.user_price)).formatMoney() + '</p>',
				'</div>'
			);
		}

		plan_html = plan_html.join('');
		PlanDetails.html(plan_html);
	}

	function render_prices(discount_percentage, subtotal, subtotal_with_discount)
	{
		var subtotal_html = [];
		var total_html = [];
		var total = subtotal_with_discount + (subtotal_with_discount * tax);
		total = parseFloat(total.toFixed(8));

		subtotal_html.push(
			'<div class="col-xs-8 hide">',
				'<p>Subtotal sin descuento:</p>',
			'</div>',
			'<div class="col-xs-4 hide">',
				'<p>$' + subtotal.formatMoney() + '</p>',
			'</div>',
			'<div class="col-xs-8">',
				'<p style="color:red">Descuento:</p>',
			'</div>',
			'<div class="col-xs-4">',
				'<p style="color:red">' + discount_percentage + '%</p>',
			'</div>',
			'<div class="col-xs-8">',
				'<p>Subtotal:</p>',
			'</div>',
			'<div class="col-xs-4">',
				'<p>$' + subtotal_with_discount.formatMoney() + '</p>',
			'</div>',
			'<div class="col-xs-12 text-light">',
				'<p>+' + tax * 100 + '% (IVA)</p>',
			'</div>'
		);

		total_html.push(
			'<p class="form-control-static">',
				'<span class="pull-left">Total:</span>',
				'<strong>$' + total.formatMoney() + '</strong>',
			'</p>'
		);

		subtotal_html = subtotal_html.join('');
		SubtotalDetails.html(subtotal_html);

		total_html = total_html.join('');
		TotalDetails.html(total_html);
	}
});

jQuery(document).ready(function($){
	var CompanySettingsTable = $('#CompanySettingsTable');
	var AddCompanySetting = $('#AddCompanySetting');

	var initialize_company_settings = function(company_setting)
	{
		var company_setting_action = company_setting.find('button').off();
		var company_setting_name = company_setting.find('input').off();
		var default_value = company_setting_name.val();

		company_setting_name.on('change', function(event){
			if (confirm('¿Estás seguro de editar este campo?'))
			{
				$(this).val(this.value.replace(/\s/g, '_').toLowerCase());
			}
			else
			{
				$(this).val(default_value);
			}
		});

		company_setting_action.on('click', function(event){
			event.preventDefault();

			if (confirm('¿Estás seguro de eliminar este campo?'))
			{
				company_setting.closest('.company-setting').remove();
			}
		});
	}

	AddCompanySetting.click(function(event){
		event.preventDefault();

		var company_setting = CompanySettingsTable.find('tr.company-setting').last();
		var company_setting_clone = company_setting.clone(false, false);
		var old_id = parseInt(company_setting_clone.attr('id'));
		var new_id = old_id + 1;

		company_setting_clone.attr('id', new_id);
		company_setting_clone.find('input, textarea').each(function(){
			var new_name = $(this).attr('name').replace(old_id, new_id);

			$(this).val(null).attr('name', new_name).attr('value', null);
		});

		company_setting_clone.appendTo(CompanySettingsTable.find('tbody'));
		initialize_company_settings(company_setting_clone);
	});

	CompanySettingsTable.find('tr.company-setting').each(function(){
		initialize_company_settings($(this));
	});

	var CheckboxesSwitch = $('.input-switch');
	if (CheckboxesSwitch.length > 0)
	{
		CheckboxesSwitch.each(function(){
			element = $(this);
			if (element)
			{
				label = $('<label></label>').attr('for', element.attr('id'));
				element.after(label);
				element.addClass('input-switch-enable');
			}
		});
	}
});

jQuery(document).ready(function($){
	var modalSync = $('#AlegraSearchContacts');
	var body = modalSync.find('.modal-body');
	var loader = body.find('.loader');

	modalSync.on('show.bs.modal', function(e){
		var company_id = $(e.relatedTarget).attr('company_id');
		var alegra_id = parseInt($(e.relatedTarget).attr('data-alegra-id'));
		$.ajax({
			url: '/companies/alegra_search/' + company_id + '.json',
		}).done(function(response) {
			if (response.error)
			{
				location.reload();
				return;
			}
			var match = response.matches;
			var checked;
			loader.addClass('hide');
			for (var x in match){
				var link = '';
				if (parseInt(match[x].alegra_id) > 0)
				{
					var contact_url = 'https://app.alegra.com/client/view/id/' + match[x].alegra_id;
					link = '<a target="_blank" href="' + contact_url + '">(Abrir en Alegra)</a>';
				}
				checked = parseInt(match[x].alegra_id) === alegra_id || (parseInt(x) === 0 && match.length === 1);
				var elements = [
					'<div class="form-group">',
					'<div class="col-xs-1 col-sm-2 text-right">',
					'<input ',
					'id="match_', x, '" type="radio" name="alegra_id" class="alegra-contact"',
					'value="', match[x].alegra_id, '" ',
					checked ? 'checked="checked"' : '', '/>',
					'</div>',
					'<div class="col-xs-11 col-sm-10">',
					'<label style="font-weight:normal; color: #777777;" for="match_', x, '">', match[x].trade_name, '</label>',
					'<small class="text-muted"> ', match[x].rfc, '</small> ',
					link,
					'</div>',
					'</div>'
				];

				body.append(elements.join(''));
			}

			$('.alegra-contact').click(function(){
				$('.alegra-contact').parent().parent().find('label').attr('style', 'font-weight:normal; color: #777777;');
				$(this).parent().parent().find('label').attr('style', '');
			});

			$('.alegra-contact').parent().parent().find('label').attr('style', 'font-weight:normal; color: #777777;');
			$('.alegra-contact[checked="checked"]').parent().parent().find('label').attr('style', '');
		});
	});

	modalSync.on('hidden.bs.modal', function(e){
		loader.removeClass('hide');
		body.find('.form-group').remove();
	});

	var modalId = $('#AlegraIdContact');
	var bodyId = modalId.find('.modal-body');
	var loaderId = bodyId.find('.loader');
	var CompanySettingAlegraId = $('#CompanySettingAlegraId');
	var contacts_loaded = false;
	modalId.on('show.bs.modal', function(e){
		var alegra_id = $(e.relatedTarget).attr('data-alegra-id');
		if (contacts_loaded)
		{
			CompanySettingAlegraId.val(alegra_id).change();
			return true;
		}
		$.ajax({
			url: '/companies/alegra_contacts.json',
		}).done(function(response) {
			if (response.error)
			{
				location.reload();
				return;
			}
			var contacts = response.contacts;
			var selected = '';

			for (var x in contacts)
			{
				selected = alegra_id == contacts[x].id ? 'selected="selected"' : '';
				CompanySettingAlegraId.append(
					'<option ' + selected + ' value="' + contacts[x].id + '">' + contacts[x].name + '</option>'
				);
			}
			loaderId.addClass('hide');
			bodyId.find('.form-group').removeClass('hide');
		});
		contacts_loaded = true;
	});
});
